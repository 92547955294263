#works{
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw; 
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle{
    margin: 1rem 0; 
    font-size: 3rem;
    /* margin-bottom: 1rem; */
}



.worksDesc{
    font-weight: 300; 
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs{
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 65rem;
    max-width: 65rem;
}

.worksImg{
    object-fit: cover;
    height: 20rem; 
    margin: 0.5rem;
}

.workBtn {
    margin: 3rem 0; 
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem; 
    background: white;
    font-size: 1rem;
}


.project {
    display: flex;
    margin-bottom: 2rem; /* Adjust margin as needed */
    align-items: center; /* Vertically center content */
}

.projectImg {
    width: 300px; /* Adjust width as needed */
    margin-right: 100px; /* Adjust margin as needed */
    margin-top: 1rem;
}

.divider {
    height: 100%;
    width: 2px;
    background-color: #000; /* Adjust color as needed */
    margin-right: 20px; /* Adjust margin as needed */
}

.content {
    flex-grow: 1;
    /* margin-top: 1rem; */
}

.projectDesc {
    font-weight: 300;
    font-size: 1rem;
    margin: 1rem 0;
    text-align: left;
}

/* Adjust the width of the image and content */
.clientImg,
.content {
    width: 50%;
}

@media screen and (max-width: 585px){
    .worksImg{
        height: 48vw;
    }
    
}